import React, { useContext } from "react";
import styled from "styled-components";

import { FirebaseContext } from "../../components/Firebase";
import { ContentBg, ContentBox } from "../../components/ui";
import Seo from "../../components/seo";

const StyledList = styled.ul`
  > li {
    margin-bottom: 1rem;
  }
  strong {
    font-size: 1.1rem;
  }
`;

const Rules = () => {
  const { userLocale } = useContext(FirebaseContext);

  return (
    <ContentBg src="/static/c41c656219d120e7127cf400171a9d84/4c91a/players.png">
      <ContentBox>
        <Seo title="World Cup Poule 2022" />
        {userLocale.indexOf("nl") > -1 ? (
          <div>
            <h1>World Cup Poule 2022 - Spelregels</h1>
            <StyledList>
              <li>
                Vul alle scores en antwoorden in <strong>voor</strong> 20
                november!
              </li>
              <li>
                Mocht je geen tijd/zin hebben om alle 64 vragen in te vullen
                (echt?), dan staat er bovenaan een knop waarmee alles
                automagisch wordt ingevuld voor je. Je kunt vervolgens nog wel
                zelf scores en antwoorden veranderen.
              </li>
              <li>
                Voor de eerste 48 wedstrijden (de Poule fase) is de
                puntenverdeling als volgt:
                <ul>
                  <li>
                    Voorspel het juiste resultaat (winst/verlies/gelijkspel):{" "}
                    <strong>5</strong> punten
                  </li>
                  <li>
                    Voorspel de juiste uitslag: <strong>10</strong> extra
                    punten!
                  </li>
                  <li>
                    Geef het juiste antwoord op de vraag: <strong>10</strong>{" "}
                    punten!
                  </li>
                </ul>
                Met andere woorden: je kunt <strong>25</strong> punten per
                wedstrijd verdienen!
              </li>
              <li>
                Voor de 2e ronde (laatste 16) gelden dezelfde regels, MAAR je
                moet nu ook de landen voorspellen die in deze wedstrijden gaan
                spelen!
                <ul>
                  <li>
                    Voorspel het juiste land: <strong>15</strong> punten per
                    land!
                  </li>
                  Met andere woorden, je kunt maximaal <strong>55</strong>{" "}
                  punten per wedstrijd verdienen tijdens deze ronde!
                </ul>
              </li>
              <li>
                Voor de Kwartfinale:
                <ul>
                  <li>
                    Voorspel het juiste land: <strong>25</strong> punten per
                    land!
                  </li>
                  Met andere woorden, je kunt maximaal <strong>75</strong>{" "}
                  punten per wedstrijd verdienen tijdens deze ronde!
                </ul>
              </li>
              <li>
                Voor de Halve Finales en Finale:
                <ul>
                  <li>
                    Voorspel het juiste land: <strong>35</strong> punten per
                    land! Het maakt NIET uit of het land hebt gekozen als Thuis-
                    of Uitspelend.
                  </li>
                  Met andere woorden, je kunt maximaal <strong>95</strong>{" "}
                  punten per wedstrijd verdienen tijdens de finale rondes!
                </ul>
              </li>
              <li>
                Tot slot hebben we nog 2 bonusvragen, waarbij je de
                Wereldkampioen en de Topscorer van het toernooi mag voorspellen.
                Deze vragen zijn ieder goed voor <strong>50</strong> punten! 😱
              </li>
              <li>
                That's it! Stuur me (
                <span title="markkater@gmail.com">Mark</span>) gerust een
                mailtje als je vragen hebt... en een prettige WK gewenst!
              </li>
            </StyledList>
          </div>
        ) : (
          <div>
            <h1>World Cup Poule 2022 - How It Works</h1>
            <StyledList>
              <li>
                Fill out ALL scores and answers <strong>before</strong> November
                20th! (that's when the World Cup kicks off! ⚽️)
              </li>
              <li>
                In total there are 64 matches. Sounds like a lot, but you should
                be able to fill it all out in 20-30 minutes. If you don't have
                that kinda time (really?), there's a button at the very top that
                will fill everything out for you / give you a head start. You
                can then review/adjust the everything to your liking.
              </li>
              <li>
                For the first 48 matches (the Group stages), predict the Score
                and Answer the question.
                <ul>
                  <li>
                    Predict the correct result (win/loss/tie):{" "}
                    <strong>5</strong> points
                  </li>
                  <li>
                    Predict the exact right score: <strong>10</strong>{" "}
                    additional points!
                  </li>
                  <li>
                    Predict the right Answer: <strong>10</strong> points!
                  </li>
                </ul>
                In other words: you can earn up to <strong>25</strong> points
                per match!
              </li>
              <li>
                For the round of 16 (<em>after</em> the Group stages), the same
                rules apply, BUT you also have to predict which countries will
                advance to these rounds!
                <ul>
                  <li>
                    Predict the right country: <strong>15</strong> points per
                    country!
                  </li>
                </ul>
                In other words: you can earn up to <strong>55</strong> points
                per match during this round!
              </li>
              <li>
                For the Quarter Finals:
                <ul>
                  <li>
                    Predict the right country: <strong>25</strong> points per
                    country.
                  </li>
                </ul>
                In other words: you can earn up to <strong>75</strong> points
                per match during this round!
              </li>
              <li>
                For the Semi Finals and Finals:
                <ul>
                  <li>
                    Predict the right country: <strong>35</strong> points per
                    country. It does NOT matter whether you picked them as the
                    Home or Away country!
                  </li>
                </ul>
                In other words: you can earn up to <strong>95</strong> points
                per match during this round!
              </li>
              <li>
                Finally, there are 2 bonus questions, where you predict both the
                World Cup winner and the Top Scorer. Answering each of these
                questions correctly will earn you an additional{" "}
                <strong>50</strong> points! 😱
              </li>
              <li>
                That's it -- let me (
                <span title="markkater@gmail.com">Mark</span>) know if you have
                any questions and enjoy the World Cup!
              </li>
            </StyledList>
          </div>
        )}
      </ContentBox>
    </ContentBg>
  );
};

export default Rules;
